
export const session = {
  // 读取sessionStorage
  get: key => {
    try {
      return JSON.parse(window.sessionStorage.getItem(key))
    } catch (err) {
      return window.sessionStorage.getItem(key)
    }
  },
  // 创建sessionStorage
  set: (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  // 移除指定sessionStorage
  remove: key => {
    window.sessionStorage.removeItem(key)
  },
  // 清空所有sessionStorage
  clear: () => {
    window.sessionStorage.clear()
  }
}